<template>
      <div class="vtu-account-view">
        <navigation ref="navigation" />
        <header-section 
            :title="content.title"
            :subtitle="content.description"
        />
        <vtu-simulator
            class="vtu--account"
            :type="'vtu--account'"
            :title="content.simulator.title"
            :subtitle="content.simulator.subTitle"
            :infoField="content.simulator.infoField"
            :warningField="content.simulator.warningField"
            :tooltip="content.simulator.tooltip"
        />
<!--         <div v-for="(element, i) in content.simulator.tooltip" :key="i"
        :txt="element.item"
        ></div> -->
        <footer-section/>
    </div>
</template>

<script>

// Webgl
import WebglManager from '@/glxp/webglManager'

// Mixins
import ViewMixin from "@/mixins/viewMixin"

// Components
import Navigation from "@/components/navigation"
import Header from "@/components/global/header"
import Footer from "@/components/global/footer"
import vtuSimulator from "@/components/vtu/vtu-simulator"

export default {
    name: 'vtu-account',
    mixins: [ViewMixin],
    data: ()=>{
        return {
            visible: true,
        }
    },
    components: {
        'navigation': Navigation,
        'header-section': Header,
        'footer-section': Footer,
        'vtu-simulator': vtuSimulator
    },
    
    beforeMount() {
        this.content = this.$store.state.page["vtu-account"].data.attributes
    },
    mounted () {  
        
        this.$nextTick(()=>{
            this.glxp = WebglManager.getScene('main')
            WebglManager.loadScene('main').then(()=>{
                WebglManager.activate('main')
                this.glxp.skipIntro()
                this.show()
            })
        })
    },
    beforeDestroy () {
        this.$refs.navigation.forcedLight = false
        },
        
    methods: {
        show(){
            this.$refs.navigation.hidden = false
            this.$refs.navigation.forcedLight = true
        },
    },
}
</script>

<style lang="less" >
@import "../styles/vars.less";

.vtu--account{
    background: @sky-blue;
    
    .form.vtu-simulator-grid-item{
        background: @sky-blue;
    }
}

</style>