import CtaBlock from '@/components/global/cta-block';
import { TimelineMax, Power2 } from "gsap"
import RAF from '@/js/utils/raf'
import ImageSequenceLoader from '@/js/utils/imageSequence'
import PhoneContainer from '@/components/global/phone-2D-container'
import mParticleConfig from '@/js/tagging/mpartic.js'

export default {
    name: 'home-cta-block-1',
    extends: CtaBlock,
    props: ["cards", "mobileVideo", "description"],
    components: {
        'phone': PhoneContainer
    },
    data: () => {
        return {
            isIntersecting: false,
            progressTarget: 0,
            progressValue: 0,
            progressBeforeTarget: 0,
            progressBeforeValue: 0,
            lastProgressPhone: 0,
            lastPhoneVideoStep: 1,
        }
    },
    mounted() {
        this.loadImages()
        this.initTimeline()
        this.$nextTick(() => {
            this.initTimeline()
            RAF.suscribe('home-cta-block-1-update', this.update.bind(this))
        })
    },

    beforeDestroy() {
        RAF.unsuscribe('home-cta-block-1-update')
    },

    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },

        loadImages() {
            this.imageSequence = new ImageSequenceLoader(this.$refs["home-cta-block-1-canvas"], "/image-sequence/Bubbles.json", "/image-sequence/Bubbles.bin")
        },

        initTimeline() {
            this.tl = new TimelineMax({
                paused: true
            })

            if (this.mobile) {
                
            } else {

                let tmpPhoneScale = { s: 1, y: 0, blur: 0 }
                this.tl.to(tmpPhoneScale, 3, { s: .8, ease: Power2.easeInOut, onUpdate: () => { this.$root.phone.s = tmpPhoneScale.s } }, "0")

                this.tl.to(this.$refs["home-cta-block-1-canvas"], 2, { autoAlpha: 0 }, "2.2")

            }
            
            this.tl.add(()=>{}, "5")

        },
        progress(p) {
            this.progressTarget = p.in
            this.progressBeforeTarget = p.before

            let phoneVideoStep = Math.min(Math.floor(p.before * 2), 1)
            if (phoneVideoStep !== this.lastPhoneVideoStep) {
                if (this.$root.phone) {
                        this.$root.phone.gotToSlide(phoneVideoStep + 1)

                        this.imageSequence.play = false
                        this.imageSequence.currentImg = 0
                        setTimeout(() => {
                            this.imageSequence.play = true
                        }, 3500);
                }
            }
        },

        update() {

            this.imageSequence.update()

        },
        
        mparticleButton(eventName){
            if(mParticleConfig.mParticleReady){
                mParticle.logEvent( 'web_home_'+eventName, mParticle.EventType.Navigation );
            }
        }
    }
}